<div class="row">
    <div class="col-md-8">
        <a class="btn btn-primary" target="_blank" href="https://web.mail.mil/">DOD Web Mail</a>
        &nbsp;
        <a class="btn btn-primary" target="_blank" href="https://www.us.army.mil/suite/login/welcome.html">AKO</a>
        &nbsp;
        <a class="btn btn-primary" target="_blank" href="http://jko.jten.mil/">JKO</a>
        &nbsp;
        <a class="btn btn-primary" target="_blank" href="https://drive.google.com/folderview?id=0B56Tp5aEAEBKWklrYm1DVWtUOEE&usp=sharing_eid&ts=577db3df">Dustdevil Library</a>
    </div>
    <div class="col-md-4">
    </div>
</div>
<div class="row">
    <div class="col-md-8">
        <!-- -----------------------------------------------------------------------------------------------------------------*@
        Gmail calendar
        -----------------------------------------------------------------------------------------------------------------*@ -->
        <br/>
        <iframe src="https://calendar.google.com/calendar/embed?height=550&amp;wkst=1&amp;bgcolor=%23999999&amp;src=robe9u80qm7nj4pg7r22a9ii8k%40group.calendar.google.com&amp;color=%23B1440E&amp;ctz=America%2FLos_Angeles" style="border:solid 1px #777" width="800" height="550" frameborder="0" scrolling="no"></iframe>
        <!-- -----------------------------------------------------------------------------------------------------------------*@ -->


    </div>
    <div class="col-md-4">
    </div>
</div>
