<div class="row">
    <div class="col-md-4">
        <a class="btn btn-primary" target="_blank" href="http://www.faa.gov/">faa.gov</a>
        <br /><br />
        <a class="btn btn-primary" target="_blank"
            href="http://www.ecfr.gov/cgi-bin/text-idx?c=ecfr&tpl=/ecfrbrowse/Title14/14tab_02.tpl">FAA Regulations</a>
        <br /><br />
        <a class="btn btn-primary" target="_blank"
            href="http://www.ecfr.gov/cgi-bin/text-idx?SID=113fa2bb2c2de9d765ebda3e83143274&mc=true&node=pt14.2.91&rgn=div5">FAR
            Part 91</a>
        <br /><br />
        Current FAR/AIM
    </div>
    <div class="col-md-4">
        &nbsp;
    </div>
    <div class="col-md-4">
        &nbsp;
    </div>
</div>