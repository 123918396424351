<div class="row">
    <div class="col-md-4">
        <a class="btn btn-primary" target="_blank" href="http://aviationweather.gov/metar?zoom=6&lat=45.26772&lon=-117.39942&layers=B00FFTFFFFFTT&plottype=model&scale=1&density=0&metric=false&decoded=false&taf=false">METAR for PNW</a>
        <br /><br />
        <a class="btn btn-primary" target="_blank" href="https://www.1800wxbrief.com/Website/weather/graphics/viewer?product=BASE_REF_KPDT&page=WEATHER&seed=1467511600972&productType=SSR">PDT RADAR</a>
        <br /><br />
        <a class="btn btn-primary" target="_blank" href="http://radar.weather.gov/Conus/pacnorthwest_loop.php">NOAA PNW RADAR</a>
        <br /><br />
        <a class="btn btn-primary" target="_blank" href="http://radar.weather.gov/Conus/pacnorthwest_loop.php">NOAA PNW RADAR</a>
    </div>
    <div class="col-md-4">
        <a class="btn btn-primary" target="_blank" href="http://radar.weather.gov/Conus/pacsouthwest.php">NOAA PACSW RADAR</a>
        <br /><br />
        <a class="btn btn-primary" target="_blank" href="http://www.aviationweather.gov">AviationWeather.gov</a>
        <br /><br />
        <a class="btn btn-primary" target="_blank" href="http://www.aviationweather.gov/metar">METAR/TAF</a>
        <br /><br />
        <a class="btn btn-primary" target="_blank" href="https://www.1800wxbrief.com/">Flight Service</a>
 </div>
    <div class="col-md-4">
    </div>
</div>
