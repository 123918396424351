<div class="row">
    <div class="col-md-8">
        <a class="btn btn-primary" target="_blank" href="https://www.notams.faa.gov/dinsQueryWeb/">Defense Internet NOTAM Service</a>
        &nbsp;
        <a class="btn btn-primary" target="_blank" href="https://pilotweb.nas.faa.gov/PilotWeb/">FAA NOTAMS</a>

    </div>
    <div class="col-md-4">
    </div>
</div>

