import { Component } from '@angular/core';

@Component({
  selector: 'app-sunmoon',
  standalone: true,
  imports: [],
  templateUrl: './sunmoon.component.html',
  styleUrl: './sunmoon.component.scss'
})
export class SunmoonComponent {

}
