<div class="row">
    <div class="col-md-8">
        <a class="btn btn-primary" href="http://www.fltplan.com/">FLTPLAN.com</a>
        <br /><br />
        <a class="btn btn-primary" href="https://skyvector.com/">SkyVector.com</a>
        <br /><br />
        <a class="btn btn-primary" href="https://www.airnav.com">AirNav.com</a>
        <br /><br />
        <a class="btn btn-primary" href="http://www.navplan.com">NavPlan.com</a>
    </div>
    <div class="col-md-4">
    </div>
</div>
<div class="row" hidden>
    <div class="col-md-8">
        <br /><br />
        Sun/Moon data <br />
        FAA NOTAMS<br />
        Military NOTAMS <br />
    </div>
    <div class="col-md-4">
    </div>
</div>