import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DustdevilsComponent } from './dustdevils/dustdevils.component';
import { CalculationsComponent } from './calculations/calculations.component';
import { FlightplanningComponent } from './flightplanning/flightplanning.component';
import { HorizonairComponent } from './horizonair/horizonair.component';
import { NotamsComponent } from './notams/notams.component';
import { RegulationsComponent } from './regulations/regulations.component';
import { SunmoonComponent } from './sunmoon/sunmoon.component';
import { TrainingComponent } from './training/training.component';
import { WeatherComponent } from './weather/weather.component';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: 'home',
        component: HomeComponent,
        title: 'CH47F Home'
      },
      {
        path: 'calculations',
        component: CalculationsComponent,
        title: 'Calculations'
      },
      {
        path: 'dustdevils',
        component: DustdevilsComponent,
        title: 'Dust Devils'
      },
      {
        path: 'flightplanning',
        component: FlightplanningComponent,
        title: 'Flight Planning'
      },
      {
        path: 'horizonair',
        component: HorizonairComponent,
        title: 'Horizon Air'
      },
      {
        path: 'notams',
        component: NotamsComponent,
        title: 'NOTAMS'
      },
      {
        path: 'regulations',
        component: RegulationsComponent,
        title: 'Regulations'
      },
      {
        path: 'sunmoon',
        component: SunmoonComponent,
        title: 'Sun and Moon'
      },
      {
        path: 'training',
        component: TrainingComponent,
        title: 'Training'
      },
      {
        path: 'weather',
        component: WeatherComponent,
        title: 'Weather'
      },
      {
        path: '**',
        redirectTo: 'home',
        pathMatch: 'full'
      }
    ])

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
