import { Component } from '@angular/core';

@Component({
  selector: 'app-calculations',
  standalone: true,
  imports: [],
  templateUrl: './calculations.component.html',
  styleUrl: './calculations.component.scss'
})
export class CalculationsComponent {

}
