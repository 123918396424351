<div class="row">

    <div class="col-md-4">
        <a class="btn btn-primary" href="https://web.mail.mil/" target="_blank">DOD Web Mail</a>
    </div>
    <div class="col-md-4">
       
    </div>
</div>

<div class="row">
    <div class="col-md-4">
        <br/>
        <!-- Display Zulu Time -->
        <iframe src="http://free.timeanddate.com/clock/i59otdq3/fcf90/tc000/ftb/bo2/pa4/tt0/tw0/tm1/th1/ts1/ta1/tb2" frameborder="0" width="172" height="26"></iframe>
        <br/>
        <!--Display PDT Clock-->
        <iframe src="http://free.timeanddate.com/clock/i59otdq3/n202/fcf90/tc000/ftb/bo2/pa4/tt0/tw0/tm1/th1/ts1/ta1/tb2" frameborder="0" width="171" height="26"></iframe>
        <br/>
        <br />


        <a class="btn btn-primary" target="_blank" href="http://www.aviationweather.gov/metar/data?ids=KPDT&format=raw&date=0&hours=4&taf=on" >METAR PDT</a>
        <br />
        <br />

        <a class="btn btn-primary" target="_blank" href="http://www.aviationweather.gov/metar/data?ids=KPDT%2CKPSC%2CKALW&format=raw&date=0&hours=4&taf=on">METAR PDT-PSC-ALW</a>
        
    </div>
</div>
